import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import ProgressTracker from "../components/ProgressTracker";
import { useRegisterSellerMutation } from "../slices/sellerApiSlice";
import OtpRegisterScreen from "./OtpRegisterScreen";
import { useSelector } from "react-redux";
const RegisterSellerScreen = () => {
  const [step, setStep] = useState(1); // Step state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });
  const { userInformation } = useSelector((state) => state.auth);
  const [registerSeller, { isLoading }] = useRegisterSellerMutation();

  const handleOtpSuccess = () => {
    setStep(2); // Move to the next step after OTP is verified
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      await registerSeller({
        // firstName: formData.firstName,
        // lastName: formData.lastName,
        // ContactPhoneNumber: formData.phoneNumber,
        userId: formData.email,
        // password: formData.password,
      }).unwrap();
      toast.success("Seller Created Successfully");
      // You might uncomment this if you want to navigate after success
      // setTimeout(() => navigate(redirect), 3000);
    } catch (error) {
      toast.error(error?.data?.message || error?.error);
    } finally {
    }
  };

  return (
    <FormContainer>
      <ProgressTracker currentStep={step} />

      <h2>Register Seller</h2>
      {step === 1 && (
        <OtpRegisterScreen onOtpSuccess={handleOtpSuccess} type={"seller"} />
      )}
      {step === 2 && (
        <>
          <Form onSubmit={submitHandler}>
            <Form.Group className="my-2" controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={userInformation && userInformation?.Email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Button disabled={isLoading} type="submit" variant="primary">
              {isLoading ? <Loader /> : "Register Seller"}
            </Button>
          </Form>
        </>
      )}
    </FormContainer>
  );
};

export default RegisterSellerScreen;
