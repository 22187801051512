import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Container,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearCartItems } from "../slices/cartSlice";
import { useCreateOrderMutation } from "../slices/orderApiSlice";
import CheckoutSteps from "../components/CheckoutSteps";
import Message from "../components/Message";
import Loader from "../components/Loader";
const PlaceOrderScreen = () => {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const { userInformation } = useSelector((state) => state?.auth);
  const user_id = userInformation && userInformation?.NameIdentifier;
  const [createOrder, { isLoading, error }] = useCreateOrderMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cart.shippingAddress.address) {
      navigate("/shipping");
    } else if (!cart.paymentMethod) {
      navigate("/payment");
    }
  }, [cart.paymentMethod, cart.shippingAddress.address, navigate]);

  const cartItemsNew =
    cart &&
    cart.cartItems?.map((item) => {
      return {
        productID: item?.product_id?.toString(),
        quantity: item?.quantity,
        price: item?.price,
        entity_id: item?.entity_id,
        // salePrice: item?.quantity,
        // taxAmount: item?.quantity,
        // deliveryAmount: item?.quantity,
        // amount: item?.quantity,
      };
    });

  const ordersArray = cartItemsNew?.map((item) => {
    return {
      user_id,
      entity_id: item?.entity_id,
      pickup_address: "Mumbai",
      shipping_address: `${cart.shippingAddress?.address}, ${cart.shippingAddress?.city}, ${cart.shippingAddress?.postalCode}`,
      price: item?.quantity * item?.price,
      // mode_of_payment: "COD",
      orderItems: cartItemsNew
        ?.filter((element) => element?.entity_id === item?.entity_id)
        .map((element) => {
          return {
            price: element?.price,
            productID: element?.productID,
            quantity: element?.quantity,
            amount: element?.price * element?.quantity,
          };
        }),
    };
  });

  // console.log("ordersArray", ordersArray);
  const placeOrderHandler = async () => {
    try {
      await createOrder({
        orders: ordersArray,
      }).unwrap();
      dispatch(clearCartItems());
      toast.success("Order Placed");
      navigate(`/profile`);
      // navigate(`/order/${res._id}`);
    } catch (error) {
      toast.error(error?.msg);
    }
  };
  return (
    <React.Fragment>
      <CheckoutSteps step1 step2 step3 step4 />
      <Container>
        <Row>
          <Col md={8}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Shipping</h2>
                <p>
                  <strong>Address:</strong>
                  {cart.shippingAddress.address}, {cart.shippingAddress.city}{" "}
                  {cart.shippingAddress.postalCode},{" "}
                  {cart.shippingAddress.country}
                </p>
              </ListGroup.Item>

              <ListGroup.Item>
                <h2>Payment Method</h2>
                <strong>Method: </strong>
                {"COD (Cash on Delivery)"}
              </ListGroup.Item>

              <ListGroup.Item>
                <h2>Order Items</h2>
                {cart.cartItems.length === 0 ? (
                  <Message>Your Cart is Empty</Message>
                ) : (
                  <ListGroup variant="flush">
                    {cart.cartItems?.map((item) => (
                      <ListGroup.Item key={item._id}>
                        <Row>
                          <Col md={2}>
                            <Image
                              src={item.image}
                              alt={item.name}
                              fluid
                              rounded
                            />
                          </Col>
                          <Col>
                            <Link to={`/product/${item._id}`}>{item.name}</Link>
                          </Col>
                          <Col md={4}>
                            {item.quantity} x Rs.{" "}
                            {item.price ?? item?.total_price} ={" "}
                            {item.quantity * (item.price ?? item?.total_price)}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2>Order Summary</h2>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>Items</Col>
                    <Col>Rs. {cart.itemsPrice}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Shipping</Col>
                    <Col>Rs. {cart.shippingPrice}</Col>
                  </Row>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>Rs. {cart.taxPrice}</Col>
                </Row>
              </ListGroup.Item> */}
                <ListGroup.Item>
                  <Row>
                    <Col>
                      <strong>Total</strong>
                    </Col>
                    <Col>
                      <strong>Rs. {cart.itemsPrice}</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
                {error && (
                  <ListGroup.Item>
                    <Message variant="danger">
                      {JSON.stringify(error?.data?.message)}
                    </Message>
                  </ListGroup.Item>
                )}
                <ListGroup.Item>
                  <Button
                    type="button"
                    className="btn-block"
                    disabled={cart.cartItems === 0}
                    onClick={placeOrderHandler}
                  >
                    Place Order
                  </Button>
                  {isLoading && <Loader />}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PlaceOrderScreen;
